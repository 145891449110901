import React from 'react'
import { useTranslation } from 'react-i18next';

import brandImg1 from '../assets/images/brands/city-blue-jeans.webp'
import brandImg2 from '../assets/images/brands/zee-rucci.webp'
import brandImg3 from '../assets/images/brands/yeskla.webp'
import brandImg4 from '../assets/images/brands/elegance.webp'
import Container from '../components/Container'
import Title from '../components/Title'

type Props = {}

const Brands = (props: Props) => {

	const { t } = useTranslation();
    
    const brands = [
        {
            name: 'City Blue Jeans',
            img: brandImg1,
        },
        {
            name: 'Zee Rucci',
            img: brandImg2,
        },
        {
            name: 'Yeskla',
            img: brandImg3,
        },
        {
            name: 'Elegance',
            img: brandImg4,
        },
    ]
    
    return (
        <div className="relative py-16 bg-blue-500">
            <Container>
                <Title color="white">{t('brands.title')}</Title>

                <div className="relative grid grid-cols-2 md:grid-cols-4 gap-4" id="brands">
                    {brands.map((item, index) => (
                        <img src={item.img} alt={item.name} className="p-0 w-full rounded shadow-md" />
                    ))}
                </div>
            </Container>
        </div>
    )
}

export default Brands