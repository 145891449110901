import React, {useRef} from "react";
import { useTranslation } from "react-i18next";
import { Link, animateScroll as scroll } from "react-scroll";

import ToolbarLogo from '../assets/images/logo-toolbar.webp';
import BrFlag from '../assets/images/flags/flag-brazil.svg';
import UsFlag from '../assets/images/flags/flag-usa.svg';

import Footer from "../partials/Footer";

interface LandingProps {
	children: React.ReactNode;
}

const Landing = ({children}: LandingProps) => {

	const { t, i18n } = useTranslation();

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
	};

	return (
		<>
			<div className="w-full absolute top-0 left-0 p-4 lg:p-8 z-10">
				<div className="flex flex-row items-center justify-between">
					<div className="flex flex-row gap-2 lg:gap-4 items-center justify-start">
						<img src={BrFlag} alt={ t('locales.ptBr') } className="w-8 lg:w-12 hover:cursor-pointer" onClick={() => handleLanguageChange('pt')} />
						<img src={UsFlag} alt={ t('locales.us') } className="w-8 lg:w-12 hover:cursor-pointer" onClick={() => handleLanguageChange('en')} />
					</div>
					<div className="flex flex-row gap-2 lg:gap-4 items-center justify-end font-bold text-sm lg:text-md">
						<Link
							to="subscribe"
							smooth={true}
							className="bg-white text-orange-500 uppercase py-1.5 px-2 rounded hover:shadow-md hover:text-white hover:bg-orange-500"
						>
							{t('header.subscribe')}
						</Link>
						<ul className="text-white px-2 flex items-center justity-center gap-2 hover:cursor-pointer hover:text-orange-500">
							<span className="uppercase">{t('header.menu')}</span>
							<svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
								<path
									fill="currentColor"
									fill-rule="evenodd"
									d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
								/>
							</svg>
						</ul>
					</div>
				</div>
			</div>

			<main className="w-full">{children}</main>

			<Footer />
		</>
	);
}

export default Landing;