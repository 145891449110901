import React from "react";

interface ContainerProps {
    maxWidth: "sm" | "md" | "lg" | "xl" | "2xl";
    paddingBlock: number;
    children: React.ReactNode;
}

const Container = ({ maxWidth, paddingBlock, children }: ContainerProps) => {
    const padding = `${paddingBlock}rem`;

    return (
        <div className={`mx-auto max-w-screen-${maxWidth} px-2 sm:px-4 lg:px-6`} style={{ paddingBlock: padding }}>
            {children}
        </div>
    );
};

Container.defaultProps = {
	maxWidth: "xl",
	paddingBlock: 0,
};

export default Container;
export { Container as SectionContainer}