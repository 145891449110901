import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Video1 from '../assets/images/videos/julia-gustavo.webp';
import Video2 from '../assets/images/videos/maryana.webp';
import Video3 from '../assets/images/videos/alice.webp';

import PlayBtn from '../assets/images/play-button.svg';
import QuoteImg from '../assets/images/quote.svg';

interface Video {
	title: string;
	thumb: string;
	youtube: string;
}

const Videos = () => {

	const { t } = useTranslation();

	const videos: Video[] = [
		{
			"title": 'Julia & Gustavo',
			"thumb": Video1,
			"youtube": "wSSznTyoZic"
		},
		{
			"title": 'Maryana',
			"thumb": Video2,
			"youtube": "x0TpMlpbfVc"
		},
		{
			"title": 'Alice',
			"thumb": Video3,
			"youtube": "oex7XIbmbBs"
		},
	];

	const [showModal, setShowModal] = useState(false);
	const [currentVideo, setCurrentVideo] = useState<string>('D112i1ZMEzI');
	const toggleVideoModal = () => setShowModal(!showModal);
	const toggleCurrentVideo = (videoId: string) => setCurrentVideo(videoId);

	useEffect(() => {
		const modal = document.getElementById("video-modal");

		if (modal) {
			if (showModal) {
				modal.classList.add("opacity-100", "scale-100");
				modal.classList.remove("opacity-0", "scale-90");
			} else {
				modal.classList.add("opacity-0", "scale-90");
				modal.classList.remove("opacity-100", "scale-100");
			}
		}
	}, [showModal]);

	return (
		<div className="relative w-full pt-16">
			<div className="w-full">
				<img src={QuoteImg} alt="Quote" className="w-16 h-16 mx-auto" />
				<h2 className="text-5xl mb-16 font-bold text-center text-black">{ t('testimonials.title') }</h2>
			</div>
			<div className="w-full grid grid-cols-2 lg:grid-cols-3" id="videos">
				{videos.map((video, index) => (
					<div className={`relative cursor-pointer group ${index === 8 ? 'max-lg:col-span-2' : ''}`} key={index} onClick={() => {toggleVideoModal(); toggleCurrentVideo(video.youtube)}}>
						<div className="p-2 absolute group-hover:hidden top-0 left-0 w-full h-full bg-black text-white bg-opacity-60 flex flex-col items-center text-center justify-center">
							<h3 className="font-poppins font-bold text-md md:text-xl lg:text-2xl">{ video.title }</h3>
						</div>
						<div className="w-full">
							<img src={video.thumb} alt={video.title} className="w-full float-left block" />
						</div>
						<div className="opacity-0 group-hover:opacity-100 absolute top-0 left-0 w-full h-full transition-opacity bg-blue-500 bg-opacity-80 flex items-center justify-center">
							<img src={PlayBtn} alt={video.title} className="w-20" />
						</div>
					</div>
				))}

				{showModal && (
					<div className="fixed inset-0 top-0 left-0 w-screen h-screen bg-black bg-opacity-75 flex flex-col gap-4 items-center justify-center z-50" id="video-modal">
						<div className="relative bg-white shadow-md border-2 border-white w-5/6 md:w-2/3 lg:w-1/2">
							<div className="aspect-w-16 aspect-h-9">
								<iframe
									allowFullScreen
									src={`https://www.youtube.com/embed/${currentVideo}?rel=0&showinfo=0`}
									allow="autoplay"
								>
								</iframe>
							</div>
						</div>
						<div className="border-4 border-white font-poppins font-bold text-md uppercase py-3 px-4 rounded text-white hover:cursor-pointer hover:bg-white hover:text-black" onClick={toggleVideoModal}>{ t('modal.close') }</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Videos;