import React from 'react'
import { useTranslation } from 'react-i18next';

import ModelImg1 from '../assets/images/models/1.webp';
import ModelImg2 from '../assets/images/models/2.webp';
import ModelImg3 from '../assets/images/models/3.webp';
import ModelImg4 from '../assets/images/models/4.webp';
import ModelImg5 from '../assets/images/models/5.webp';
import ModelImg6 from '../assets/images/models/6.webp';
import ModelImg7 from '../assets/images/models/7.webp';
import ModelImg8 from '../assets/images/models/8.webp';
import ModelImg1Mobile from '../assets/images/models/1-mobile.webp';
import ModelImg2Mobile from '../assets/images/models/2-mobile.webp';
import ModelImg3Mobile from '../assets/images/models/3-mobile.webp';
import ModelImg4Mobile from '../assets/images/models/4-mobile.webp';
import ModelImg5Mobile from '../assets/images/models/5-mobile.webp';
import ModelImg6Mobile from '../assets/images/models/6-mobile.webp';
import ModelImg7Mobile from '../assets/images/models/7-mobile.webp';
import ModelImg8Mobile from '../assets/images/models/8-mobile.webp';

const Models = () => {

	const { t } = useTranslation();

	const data = [
		{
			name: 'Matteo Ricca Zago',
			images: [ModelImg1, ModelImg1Mobile],
		},
		{
			name: 'Alice Gamba e Silva',
			images: [ModelImg2, ModelImg2Mobile],
		},
		{
			name: 'Yasmin Andy M. Itsubaschi',
			images: [ModelImg3, ModelImg3Mobile],
		},
		{
			name: 'Yasmin Hantmann Vieira',
			images: [ModelImg4, ModelImg4Mobile],
		},
		{
			name: 'Pietro Rocha',
			images: [ModelImg5, ModelImg5Mobile],
		},
		{
			name: 'Antonella Bitencurt Nascimento',
			images: [ModelImg6, ModelImg6Mobile],
		},
	];

	return (
		<div className="relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-0" id="models">
			{data.map((item, index) => (
				<div className="relative bg-gray-200 h-64 md:h-screen overflow-hidden" key={index}>
					<picture>
						<source srcSet={item.images[1]} media="(max-width: 959px)" />
						<source srcSet={item.images[0]} media="(min-width: 960px)" />
						<img src={item.images[0]} alt={item.name} className="p-0 w-full h-full object-cover object-top" />
					</picture>
				</div>
			))}
		</div>
 	)
}

export default Models;