import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import ColorBlock from '../components/ColorBlock';
import Title from '../components/Title';

const About = () => {

	const { t } = useTranslation();

	return (
		<div className="relative w-full py-16 shadow-inner bg-gray-100" id="about">
			<Container>
				<Title>{t('about.mega_in_numbers')}</Title>

				<div className="grid grid-cols-2 gap-4 lg:grid-cols-4 ">
					<ColorBlock
						title='100k+'
						description={t('about.numbers.jobs')}
						color='pink'
						className='rounded-xl lg:rounded-2xl shadow-md lg:shadow-lg'
					/>
					<ColorBlock
						title='5k+'
						description={t('about.numbers.models')}
						color='blue'
						className='rounded-xl lg:rounded-2xl shadow-md lg:shadow-lg'
					/>
					<ColorBlock
						title='5k+'
						description={t('about.numbers.costumers')}
						color='green'
						className='rounded-xl lg:rounded-2xl shadow-md lg:shadow-lg'
					/>
					<ColorBlock
						title='5M+'
						description={t('about.numbers.payed')}
						color='yellow'
						className='rounded-xl lg:rounded-2xl shadow-md lg:shadow-lg'
					/>
				</div>
			</Container>
		</div>
 	);
}

export default About