import { type } from 'os'
import React from 'react'

type Props = {
    color: 'blue'|'pink'|'yellow'|'green'|'white'|'black',
	children: React.ReactNode,
	className: string,
	tag: 'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
}

const Title = (props: Props) => {

    const classColors = {
        blue: 'text-blue-500',
        pink: 'text-pink-500',
        green: 'text-green-500',
        yellow: 'text-yellow-500',
		white: 'text-white',
		black: 'text-black',
    }

	const titleClass = `${classColors[props.color]} ${props.className} text-center text-2xl lg:text-6xl font-poppins font-semibold mb-8 uppercase`;

	return (
		<h2 className={titleClass}>{props.children}</h2>
	)
}

Title.defaultProps = {
	color: 'blue',
    className: '',
	tag: 'h2',
};

export default Title;