import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from "react-scroll";
import RegisterForm from '../partials/RegisterForm';

import RegisterImg from '../assets/images/register.webp';
import InstagramIcon from '../assets/images/instagram.svg';
import FacebookIcon from '../assets/images/facebook.svg';

import HomeHeader from '../partials/HomeHeader';
import About from '../partials/About';
import HowItWorks from '../partials/HowItWorks';
import Models from '../partials/Models';
import Videos from '../partials/Videos';
import Brands from '../partials/Brands';

export default function Home () {

	const { t } = useTranslation();
 	
	return (
		<>
			<main id="main">
				<HomeHeader />
				<div className="w-full relatie bg-gray-100">
					<About />
					<HowItWorks />
					<Brands />
					<Models />
					<Videos />
				</div>

				<div className="relative w-full lg:grid lg:grid-cols-2 bg-blue-500">
					<div className="h-full text-white">
						<div className="w-full lg:h-1/2">
							<img src={RegisterImg} alt={ t('form.title') } className="w-full h-full object-cover" />
						</div>
						<div className="w-full lg:h-1/2 p-4 max-lg:text-center lg:p-10 flex flex-col items-center justify-center">
							<p className="font-poppins text-lg lg:text-2xl">{ t('form.description') }</p>
							<div className="w-full flex flex-row items-center justify-center lg:items-left lg:justify-start gap-2 mt-4">
								<a href="https://www.instagram.com/megamodelkids" target="_blank" className="border-2 border-white rounded p-2 hover:bg-gray-700">
									<img src={InstagramIcon} alt="Instagram" className="w-8 h-8" />
								</a>
								<a href="https://www.facebook.com/megamodelkids" target="_blank" className="border-2 border-white rounded p-2 hover:bg-gray-700">
									<img src={FacebookIcon} alt="Facebook" className="w-8 h-8" />
								</a>
							</div>
						</div>
					</div>

					<Element name="subscribe" className="max-lg:py-8 w-full flex flex-col p-2 md:p-4 lg:p-8 items-center justify-center bg-pink-500" id="subscribe">
						<h2 className="mb-4 text-5xl text-center w-full font-poppins font-bold text-white">{ t('form.title') }</h2>
						<div className="bg-white rounded p-8 w-full md:shadow-lg">
							<RegisterForm />
						</div>
					</Element>
				</div>
			</main>
		</>
	);
}
