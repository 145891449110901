import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import Particles from 'react-particles';
import { Container, Engine, circleBounce} from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import { SectionContainer } from "../components/Container"

import ImgBanner from '../assets/images/logo-banner.svg';

import Planet1 from '../assets/images/planets/planet-1.gif';
import Planet2 from '../assets/images/planets/planet-2.gif';
import Planet3 from '../assets/images/planets/planet-3.gif';
import Planet4 from '../assets/images/planets/planet-4.gif';
import Rocket from '../assets/images/planets/rocket.svg';

import { env } from 'process';

const HomeHeader = () => {
	const { t } = useTranslation();

	const particlestInit = useCallback(async (engine: Engine) => {
		await loadSlim(engine);
	}, []);

	const particlesLoaded = useCallback(async (container: Container | undefined) => {
		await console.log(container);
	}, []);

	return (
		<div className="relative w-full block overflow-hidden bg-gradient-to-b from-black to-gray-900">
			<header
				className="relative max-w-full w-full h-screen flex flex-col items-center justify-center"
				id="header"
				role="header"
			>
				<img
					src={ImgBanner}
					alt={process.env.REACT_APP_NAME}
					className="w-md max-w-md lg:w-xl lg:max-w-xl object-contain z-20"
				/>

				<div className="flex flex-col items-center p-4 justify-center bg-white bg-opacity-90 rounded-t-md absolute bottom-0 max-w-[90%] lg:max-w-2xl z-20 font-semibold text-md text-center">
					<h1 className="text-gray-900 lg:text-lg mb-4">
						{t('header.subtitle')}
					</h1>
					<div className="relative bg-orange-500 py-2 px-4 rounded-md text-white uppercase overflow-hidden hover:cursor-pointer">
						<Link smooth={true} to="about" className="z-10 relative">{t('header.click_here')}</Link>
						<div className="absolute top-0 left-0 w-full h-full animate-pulse bg-red-500 z-0"></div>
					</div>
				</div>
				
				<SectionContainer>
					<img className="max-lg:hidden absolute left-32 -bottom-8 w-40 h-40 z-10 hover:w-48 hover:h-48 hover:rotate-12 transition-all duration-500" src={Planet1} alt={process.env.REACT_APP_NAME} />
					<img className="absolute -left-10 lg:left-[15%] bottom-80 w-20 h-20 z-10 hover:w-24 hover:h-24 hover:rotate-45 transition-all duration-500" src={Planet2} alt={process.env.REACT_APP_NAME} />
					<img className="absolute -right-20 lg:right-[10%] top-60 w-40 h-40 lg:w-60 lg:h-60 z-10 hover:w-72 hover:h-72 hover:-rotate-45 transition-all duration-500" src={Planet3} alt={process.env.REACT_APP_NAME} />
					<img className="absolute right-20 lg:right-[15%] top-28 lg:top-40 w-16 h-16 lg:w-24 lg:h-24 z-10 hover:w-48 hover:h-48 hover:-rotate-45 transition-all duration-1000" src={Planet4} alt={process.env.REACT_APP_NAME} />
				</SectionContainer>
			</header>

			<Element name="about" className="relative w-full flex flex-col items-center justify-center">
				<div className="relative flex flex-col items-center justiy-center text-center bg-white bg-opacity-90 rounded-b-md p-8 mb-16 max-w-[90%] lg:max-w-2xl z-20">
					<div className="w-full h-[1px] bg-gray-300 absolute top-4 left-0"></div>
					<h2 className="text-orange-500 font-poppins font-semibold text-2xl lg:text-4xl mt-2 mb-4">{t('about.the_agency')}</h2>
					<div className="text-lg text-gray-900">
						<p>{t('about.title')}</p>
						<p>{t('about.description')}</p>
					</div>
				</div>

				<img
					src={Rocket}
					alt={process.env.REACT_APP_NAME}
					className="max-xl:hidden absolute bottom-0 right-0 w-[600px] z-0 -mb-32 animate-rotate duration-1000"
				/>
				
			</Element>
			
			<Particles
				className="absolute top-0 right-0 w-full h-screen z-0"
				id="tsparticles"
				init={particlestInit}
				loaded={particlesLoaded}
				options={{
					particles: {
						number: {
							value: 500,
							density: {
								enable: true,
								value_area: 800
							}
						},
						color: {
							value: '#FFFFFF'
						},
						shape: {
							type: 'star',
							stroke: {
								width: 0
							}
						},
						polygon: {
							nb_sides: 5
						},
						opacity: {
							value: 0.5,
							random: false,
							anim: {
								enable: true,
								speed: 0.2,
								opacity_min: 0,
								sync: false
							}
						},
						size: {
							value: 3,
							random: true,
							anim: {
								enable: true,
								speed: 2,
								size_min: 0,
								sync: false
							}
						},
						line_linked: {
							enable: false,
							distance: 150,
							color: '#FFFFFF',
							opacity: 0.4,
							width: 1
						},
						move: {
							enable: true,
							speed: 0.2,
							direction: 'none',
							random: true,
							straight: false,
							out_mode: 'out',
							bounce: false,
							attract: {
								enable: false,
								rotateX: 600,
								rotateY: 1200
							}
						}
					},
					interactivity: {
						detect_on: 'canvas',
						events: {
							onhover: {
								enable: true,
								mode: 'bubble'
							},
							resize: true
						},
						modes: {
							grab: {
								distance: 400,
								line_linked: {
									opacity: 1
								}
							},
							bubble: {
								distance: 83.91608391608392,
								size: 3,
								duration: 3,
								opacity: 1,
								speed: 3
							},
							repulse: {
								distance: 20,
								duration: 0.4
							},
							push: {
								particles_nb: 4
							},
							remove: {
								particles_nb: 2
							}
						}
					},
					retina_detect: true
				}}
			/>
			
		</div>
	)
}

export default HomeHeader;