import React from 'react'

type Props = {
    color: 'blue'|'pink'|'yellow'|'green',
    title: string,
    description: string,
    className: string,
}

const ColorBlock = (props: Props) => {

    const classColors = {
        blue: 'bg-blue-500',
        pink: 'bg-pink-500',
        green: 'bg-green-500',
        yellow: 'bg-yellow-500',
    }

    return (
        <div className={`${classColors[props.color]} ${props.className} font-poppins font-semibold text-shadow flex flex-col items-center justify-center p-4 text-center h-32 lg:h-80 text-white`}>
            <div className="text-3xl md:text-5xl lg:text-7xl">{props.title}</div>
            <p className="text-md lg:text-lg">{props.description}</p>
        </div>
    )
}

ColorBlock.defaultProps = {
	color: 'blue',
    className: '',
};

export default ColorBlock
