import React from 'react'
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import AboutImg from '../assets/images/about.webp';

const HowItWorks = () => {

	const { t } = useTranslation();

	return (
		<div className="relative w-full py-8">
			<Container>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
					<figure className="lg:col-span-2">
						<img
							src={AboutImg}
							alt={process.env.REACT_APP_NAME}
							className="rounded w-full max-w-full"
						/>
					</figure>
					<div className="flex items-center h-full justify-center flex-col">
						<p className="text-lg md:text-xl lg:text-3xl font-poppins">{t('about.description')}</p>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default HowItWorks;